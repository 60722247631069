import {
    defaultLabelFontSize,
    displayStatLines,
    getCategoricalAxisOptions,
    getFormattedValue,
    getFormatter,
    getNumericalAxisOptions,
    getTooltipLabel,
    getXData,
} from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data), data.metadata, config, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            confine: true,
            textStyle: { fontSize: 0.4 * baseFontSize },
            axisPointer: { type: "shadow" },
            formatter: (params) => {
                const formatter = getFormatter(data, "y", config, false);
                const tooltipName = getTooltipLabel(params.name, data.metadata, config);
                return `${tooltipName} <br/> ${params.marker} ${formatter(params.value)}`;
            },
        },
        series: [{
            type: "bar",
            data: data.result.map(entry => entry.y[0]),
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.data, "y", data, config),
            },
            markLine: { // Categorical results may have statistical lines
                data: statisticalLines && data.variables ? displayStatLines(statisticalLines, data.variables, config) : [],
                symbol: "none",
                silent: true,
                animation: false,
                lineStyle: { width: 2 }, // this style will be applied to all lines
                label: {
                    fontSize: defaultLabelFontSize(baseFontSize),
                    formatter: function (params) {
                        return params.name + " : " + getFormattedValue(params.data.yAxis, "y", data, config);
                    },
                    position: "insideEndTop",
                },
            },
        }],
    };
};
