import { formatCompact, formatTemplate, formatValue, getCompactFormatter } from "../../util/formatter";
import theme from "../../theme/theme";

export const getXData = (data) => {
    return data.result.map(el => el.label);
};

export const getCategoricalAxisOptions = (name, data, metadata, config, baseFontSize, extraColumns) => {
    return {
        data: data,
        name: name,
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "category",
        axisLabel: {
            interval: 0, // TODO dynamic interval
            rotate: 0,
            width: baseFontSize * (15 / (data.length + (extraColumns || 0))),
            height: 2 * 0.35 * baseFontSize,
            margin: 0.25 * baseFontSize,
            overflow: "breakAll",
            lineOverflow: "truncate", // TODO this currently requires an echarts patch
            fontSize: defaultLabelFontSize(baseFontSize),
            lineHeight: 0.35 * baseFontSize,
            formatter: value => getAxisLabel(value, metadata, config),
        },
    };
};

export const getNumericalAxisOptions = (name, data, config, label_type, baseFontSize) => {
    return {
        name: name,
        nameLocation: "middle",
        nameGap: 1.5 * baseFontSize,
        nameTextStyle: {
            fontSize: 0.5 * baseFontSize,
        },
        type: "value",
        axisLabel: {
            fontSize: defaultLabelFontSize(baseFontSize),
            formatter: getCompactFormatter(data, config.locale, config.i18n, label_type === "PERCENTAGE", formatCompact),
            rich: {
                min: {
                    color: theme.palette.error.main,
                    borderWidth: 1,
                    borderColor: theme.palette.error.main,
                    borderRadius: 5,
                    borderType: "dotted",
                    padding: 5,
                    fontSize: 0.38 * baseFontSize,
                },
            },
        },
    };
};

export const getLegend = (data, config, baseFontSize) =>
    ({
        width: "64%", // Reduce chart width to give space for legend.
        legend: {
            selectedMode: false,
            orient: "vertical",
            top: "middle",
            left: "78%", // relative to container width. 78% is just enough next to the slide.
            icon: "roundRect",
            itemGap: 0.2 * baseFontSize,
            itemWidth: 0.4 * baseFontSize,
            itemHeight: 0.4 * baseFontSize,
            textStyle: {
                fontSize: 0.38 * baseFontSize,
                color: theme.palette.grey["600"],
                overflow: "truncate",
            },
            formatter: function (id) {
                return getAxisLabel(id, data.metadata, config);
            },
        },
    });

/**
 * Gets the axis label for a given ID.
 * The label will be picked based on precedence: short name, name, chart label, document label, accounting treatment label and ID.
 *
 * @param {*|string} id dimension ID, chart ID, document id or accounting treatment ID
 * @param metadata dimensions' metadata
 * @param config config
 */
export const getAxisLabel = (id, metadata, config) => {
    if (id === null) {
        return config.i18n.chart.label["__null__"];
    }

    // if there is a template, apply it
    if (metadata[id]?.template) {
        return formatTemplate(metadata[id]?.template, {}, config.locale, config.i18n);
    }

    return metadata[id]?.short_name
        || metadata[id]?.name
        || config.i18n.chart.label[id]
        || config.i18n.Document[id]
        || config.i18n.AccountingTreatment[id]
        || id;
};

/**
 * Gets the tooltip label for a given ID.
 * The label will be picked based on precedence: name, short name, chart label, document label, accounting treatment label and ID.
 *
 * @param {*|string} id dimension ID, chart ID, document id or accounting treatment ID
 * @param metadata dimensions' metadata
 * @param config config
 */
export const getTooltipLabel = (id, metadata, config) => {
    if (id === null) {
        return config.i18n.chart.label["__null__"];
    }

    // if there is a template, apply it
    if (metadata[id]?.template) {
        return formatTemplate(metadata[id]?.template, {}, config.locale, config.i18n);
    }

    return metadata[id]?.name
        || metadata[id]?.short_name
        || config.i18n.chart.label[id]
        || config.i18n.Document[id]
        || config.i18n.AccountingTreatment[id]
        || id;
};

export const getMaxLabelLength = (data, config) => {
    const labels = data.result.map(el => getAxisLabel(el.x, data.metadata, config));
    const lengths = labels.map(el => el.length);
    return Math.max(...lengths);
};

export function getFormatterFromLabelType(
    labelType,
    compact = false,
    accounting = false,
    report = false,
    long = false,
) {
    switch (labelType) {
        case "INTEGER":
            return compact ? "compactinteger" : "integer";
        case "DECIMAL":
            if (long) {
                return null;
            } else if (compact) {
                return "compact";
            } else {
                return "decimal";
            }
        case "AMOUNT":
            if (long) {
                return "longamount";
            } else if (accounting) {
                return "accountingamount";
            } else {
                return compact ? "compactamount" : "amount";
            }
        case "DATE":
            return "date";
        case "PERCENTAGE":
            if (long) {
                return "longpercentage";
            } else {
                return report ? "reportpercentage" : "percentage";
            }
        default:
            return null;
    }
}

export function getFormattedValue(
    val,
    label,
    data,
    config,
    labelType = null,
    compact = true,
    accounting = false,
    report = false,
    long = false,
) {
    return getFormatter(data, label, config, compact, accounting, labelType, report, long)(val);
}

export function getFormatter(
    data,
    label,
    config,
    compact = true,
    accounting = false,
    labelType = null,
    report = false,
    long = false,
) {
    let formatterType;
    if (typeof labelType == "string") {
        formatterType = getFormatterFromLabelType(labelType, compact, accounting, report, long);
    } else {
        formatterType = getFormatterFromLabelType(labelType?.label || data.label_type[label], compact, accounting, report, long);
    }

    if (formatterType) {
        return value => formatValue(value, formatterType, config.locale, config.i18n);
    } else {
        return value => value;
    }
}

export function getLineColor(config, param) {
    // FIXME 2868: change this to use color scheme colors
    switch (param) {
        case "MEDIAN":
            return theme.palette.echartsYellow.main;
        case "P75":
            return theme.palette.echartsOrange.main;
        case "AVERAGE":
            return theme.palette.echartsGreen.main;
        case "P25":
            return theme.palette.echartsRed.main;
    }
}

export function displayStatLines(statLinesParam, statLinesValue, config) {
    return statLinesParam
        // ignore params not present in the calculated value.
        .filter(param => statLinesValue && param in statLinesValue)
        .map((param) => {
            return {
                name: config.i18n.chart.lines[param],
                yAxis: statLinesValue[param],
                lineStyle: {
                    color: getLineColor(config, param),
                },
            };
        },
        );
}

export const defaultLabelFontSize = (baseFontSize) => {
    return 0.35 * baseFontSize;
};
