import {
    defaultLabelFontSize,
    getAxisLabel,
    getCategoricalAxisOptions,
    getFormattedValue,
    getFormatter,
    getMaxLabelLength,
    getNumericalAxisOptions,
    getTooltipLabel,
    getXData,
} from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        grid: {
            top: 1.5 * baseFontSize,
            bottom: 1.7 * baseFontSize,
            left: 2.2 * baseFontSize,
            right: 1.5 * baseFontSize,
            containLabel: true,
        },
        // Axis are swapped, since the bars are horizontal
        xAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        yAxis: {
            ...getCategoricalAxisOptions(data.labels.x, getXData(data), data.metadata, config, baseFontSize),
            inverse: true, // Get correct order of categories
            nameGap: 1.0 * baseFontSize + getMaxLabelLength(data, config) * 0.175 * baseFontSize, // Overwrite 'nameGap' to add spacing between axis name and axis labels
            // we don't want all the axisLabel customization from getCategoricalAxisOptions
            axisLabel: {
                fontSize: defaultLabelFontSize(baseFontSize),
                formatter: value => getAxisLabel(value, data.metadata, config),
            },
        },
        tooltip: {
            confine: true,
            textStyle: { fontSize: 0.4 * baseFontSize },
            axisPointer: { type: "shadow" },
            formatter: (params) => {
                const formatter = getFormatter(data, "y", config, false);
                const tooltipName = getTooltipLabel(params.name, data.metadata, config);
                return `${tooltipName} <br/> ${params.marker} ${formatter(params.value)}`;
            },
        },
        series: [{
            type: "bar",
            data: data.result.map((entry) => {
                const value = entry.y[0];
                return {
                    value: value,
                    label: { position: value >= 0 ? "right" : "left" },
                };
            }),
            label: {
                show: true,
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.data.value, "y", data, config),
            },
        }],
    };
};
