import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Drawer, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from "@mui/material";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

import CustomizationBar from "../components/CustomizationBar";
import Slide from "../components/Slide";
import { tryGetHierarchicalDimension } from "../util/util";
import { intersection, isEmpty, isEqual, isUndefined, omit, pick, without } from "lodash";
import { getDefaultByType, getOptionsByType, initializeParameterDefaults } from "../util/parameters";
import AdvisorContainer from "../layout/AdvisorContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BooleanParam, JsonParam, StringParam, useQueryParam } from "use-query-params";
import { SearchBar } from "../components/SearchBar";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { AppContext } from "../AppRouter";
import { getAnalysis, runAnalysis } from "../util/client";

const Ask = () => {
    const { client, config, notify } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(false);
    const [slideContext, setSlideContext] = useState();

    const [analysis, setAnalysis] = useState();

    const { analysisId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [analysisChart, setAnalysisChart] = useQueryParam("chart", StringParam);
    const [analysisHideInsights, setAnalysisHideInsights] = useQueryParam("hideInsights", BooleanParam);
    const [statisticalLines, setStatisticalLines] = useQueryParam("statLines", JsonParam);
    const [analysisParameters, setAnalysisParameters] = useQueryParam("params", JsonParam);

    const [filtersInDimensions, setFiltersInDimensions] = useQueryParam("filtersInDims", JsonParam);
    const [filtersInParameters, setFiltersInParameters] = useQueryParam("filtersInParams", JsonParam);
    const [filtersOutDimensions, setFiltersOutDimensions] = useQueryParam("filtersOutDims", JsonParam);
    const [filtersOutParameters, setFiltersOutParameters] = useQueryParam("filtersOutParams", JsonParam);

    const [analysisCustomParameters, setAnalysisCustomParameters] = useQueryParam("custom", JsonParam);

    const [isCustomizationDrawerOpen, setIsCustomizationDrawerOpen] = useState(true);
    const [dimensionsMetadata, setDimensionsMetadata] = useState({});

    const [contextMenuAnchor, setContextMenuAnchor] = useState();
    const [contextMenuDimId, setContextMenuDimId] = useState();

    const drawerWidth = 388;

    function updateDimensionFilters(parameters, setParameters, dimensions, setDimensions, dimensionParam, dimensionsToKeep) {
        let newDimensions = dimensionParam ? dimensions || [] : [];
        let newParameters = dimensionParam ? parameters || {} : {};

        if (dimensionsToKeep) {
            // keep dimension parameters allowed by the new analysis.
            // this results on the dimension picker to be cleared.
            if (!isEmpty(newParameters)) {
                newParameters = pick(newParameters, dimensionsToKeep);
            }

            // keep dimensions allowed by the new analysis.
            // this results on the dimension picker to be cleared.
            if (filtersInParameters !== undefined && !isEqual(newDimensions, parameters)) {
                const dimsToDelete = [...Object.keys(omit(parameters, dimensionsToKeep))];

                Object.entries(config.hierarchy).forEach(([virtualDim, dims]) => {
                    // same as dimsToDelete.containsSome(dims)
                    if (!isEmpty(intersection(dimsToDelete, dims))) {
                        dimsToDelete.push(virtualDim);
                    }
                });

                newDimensions = without(newDimensions, ...dimsToDelete);

                // make sure to notify only when needed.
                if (dimensions !== undefined && !isEqual(newDimensions, dimensions)) {
                    notify.info("askme.lost.dim.filter");
                }
            }
        }

        setDimensions(newDimensions, "replaceIn");
        setParameters(newParameters, "replaceIn");
    }

    function closeContextualMenu() {
        setContextMenuDimId(null);
        setContextMenuAnchor(null);
    }

    function drill(relatedAnalysis, searchParams, dimensions, dimParameters, dimensionId) {
        const dimensionToFilter = relatedAnalysis.filter_by;

        const newDimParameters = {
            ...dimParameters,
        };

        const oldDimParameter = newDimParameters[dimensionToFilter] || [];
        newDimParameters[dimensionToFilter] = [...oldDimParameter, dimensionId];

        // to make sure we display the entire hierarchical visual filter.
        const newDimensions = [...dimensions];
        const dimension = tryGetHierarchicalDimension(config, dimensionToFilter);
        if (!newDimensions.includes(dimension)) {
            newDimensions.push(dimension);
        }

        // HACK: We need to create the search parameters before navigating.
        // otherwise, setting the params before navigating (asynchronously) will cause the browser to navigate
        // to the analysis and ignoring the new search parameters.
        searchParams.set("filtersInParams", JSON.stringify(newDimParameters));
        searchParams.set("filtersInDims", JSON.stringify(newDimensions));

        navigate({ pathname: `../ask/${relatedAnalysis.analysis_id}`, search: searchParams.toString() });
    }

    function updateFilter(filterDimensions, setFilterDimensions, filterParameters, setFilterParameters, newDimensions, newParameters, newMetadata) {
        // only set the analysis dimension if it isn't equal to previous.
        // enforce a replaceIn to only have one history stack element.
        if (!isEqual(filterDimensions, newDimensions)) {
            setFilterDimensions(newDimensions);
            setFilterParameters(newParameters, "replaceIn");
        } else {
            setFilterParameters(newParameters);
        }

        setDimensionsMetadata({
            ...dimensionsMetadata,
            ...newMetadata,
        });
    }

    function isOldCustomParameterCompatible(oldCustomParameters, newCustomParameter) {
        return oldCustomParameters != null && newCustomParameter.name in oldCustomParameters
            && (newCustomParameter.options.includes(oldCustomParameters[newCustomParameter.name])
            || (oldCustomParameters[newCustomParameter.name].constructor === Array // CustomParameter value might be a list
            && oldCustomParameters[newCustomParameter.name].every(v => newCustomParameter.options.includes(v))));
    }

    // useCallback avoids having a new function declaration, since it's memoized on the first declaration.
    // This way these callbacks, aren't redeclared, which avoids re-rendering of the Chart component.
    const onDrillDown = useCallback((dimId) => {
        if (analysis?.relatedAnalyses?.down) {
            drill(analysis.relatedAnalyses.down, searchParams, filtersInDimensions, filtersInParameters, dimId);
        }
    }, [analysis, searchParams, filtersInDimensions, filtersInParameters]);

    const onChartContextMenu = useCallback((dimId, mouseX, mouseY) => {
        setContextMenuAnchor({
            left: mouseX,
            top: mouseY,
        });
        setContextMenuDimId(dimId);
    }, []);

    useEffect(() => {
        if (analysisId) {
            setIsLoading(true);
            setSlideContext(null);

            getAnalysis(client, analysisId)
                .then((analysis) => {
                    let chart = analysisChart || getDefaultByType(analysis.customization_parameters, "CHART");
                    let newStatisticalLines = statisticalLines || getDefaultByType(analysis.customization_parameters, "STAT_LINES");
                    let hideInsights = analysisHideInsights || getDefaultByType(analysis.customization_parameters, "HIDE_INSIGHTS");
                    // avoid adding these param types to defaults, since they are handled differently.
                    let newParameters = initializeParameterDefaults(analysis, ["CHART", "DIMENSION", "HIERARCHY", "STAT_LINES",
                        "HIDE_INSIGHTS"]);

                    // We should keep the compatible custom parameter values
                    let defaultCustomParameters = analysis.customization_parameters.filter(el => el.type === "CUSTOM");
                    let newCustomParameters = {};
                    defaultCustomParameters?.forEach((param) => {
                        if (isOldCustomParameterCompatible(analysisCustomParameters, param)) {
                            newCustomParameters[param.name] = analysisCustomParameters[param.name];
                        } else {
                            newCustomParameters[param.name] = param.value;
                        }
                    });

                    // if it isn't the first time considering the parameters, when fetching an analysis, it's because we already had parameters in another analysis.
                    // meaning this is not the first analysis seen.
                    // so let's forget parameters not supported by the new analysis and keep the old ones that are supported.
                    if (analysisParameters) {
                        // forget parameters not supported by the new analysis.
                        const paramsToForget = config.ask_me.customization_parameters.filter(param => !(param in newParameters));

                        const oldParamValues = omit(analysisParameters, paramsToForget);

                        newParameters = {
                            ...newParameters,
                            // old parameters should have precedence over the new ones.
                            ...oldParamValues,
                        };

                        // default chart type to the default, if the new analysis does not support old one.
                        const chartOptions = getOptionsByType(analysis.customization_parameters, "CHART");
                        if (!chartOptions.includes(chart)) {
                            chart = getDefaultByType(analysis.customization_parameters, "CHART");
                        }

                        // if the new analysis does not support stat lines, let's forget them.
                        if (!getOptionsByType(analysis.customization_parameters, "STAT_LINES")) {
                            newStatisticalLines = null;
                        }

                        // analysis only support annualize when aggregating by year
                        newParameters["ANNUALIZE"] = newParameters["ANNUALIZE"] && newParameters["DATE_AGG"] !== "DATE_YEAR_MONTH";
                    }

                    const dimensionParam = getOptionsByType(analysis.customization_parameters, "DIMENSION");
                    updateDimensionFilters(filtersInParameters, setFiltersInParameters, filtersInDimensions, setFiltersInDimensions, dimensionParam,
                        dimensionParam?.dimensions);
                    updateDimensionFilters(filtersOutParameters, setFiltersOutParameters, filtersOutDimensions, setFiltersOutDimensions, dimensionParam,
                        dimensionParam?.dimensions);

                    setAnalysis({
                        id: analysis.id,
                        question: analysis.question,
                        customizationParameters: analysis.customization_parameters,
                        relatedAnalyses: analysis.related_analyses,
                    });

                    // replace URL to avoid pressing back and go through a state where there isn't parameters.
                    const option = "replaceIn";
                    setAnalysisChart(chart, option);
                    setAnalysisHideInsights(hideInsights, option);
                    setStatisticalLines(newStatisticalLines, option);
                    setAnalysisParameters(newParameters, option);
                    setAnalysisCustomParameters(newCustomParameters, option);
                })
                .catch((error) => {
                    setIsLoading(false);
                    notify.error(error, "analysis.fetch");
                });
        }
    }, [analysisId]);

    useEffect(() => {
        // if there are already filters when setting the analysis, we need to fetch their metadata.
        if (analysisId && (!isEmpty(filtersInParameters) || !isEmpty(filtersOutParameters))) {
            const filters = [...Object.values(filtersInParameters), ...Object.values(filtersOutParameters)];
            const ids = Object.values(filters).flatMap(el => el).filter(el => el !== "__null__");
            client.dimension.dimensionMetadata(ids)
                .then((metadata) => {
                    setDimensionsMetadata(metadata);
                })
                .catch(error => notify.error(error, "analysis.fetch"));
        }
    }, [filtersInParameters, filtersOutParameters]);

    useEffect(() => {
        if (analysisId && analysis && analysisParameters && filtersInParameters
            && filtersOutParameters && analysisCustomParameters && analysisId === analysis.id) {
            setIsLoading(true);

            runAnalysis(client, analysisId, analysisParameters, filtersInParameters, filtersOutParameters, analysisCustomParameters)
                .then((data) => {
                    setIsLoading(false);

                    setSlideContext({
                        analysisId: data.analysis.id,
                        question: data.analysis.question,
                        data: data,
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    notify.error(error, "analysis.run");
                });
        }
    }, [analysisId, analysis, analysisParameters, filtersInParameters, filtersOutParameters, analysisCustomParameters]);

    const analysisMetadata = {
        ...dimensionsMetadata,
        ...slideContext?.data.metadata,
    };

    return (
        <>
            <AdvisorContainer title={analysis?.question || config.i18n.page.ask_me} showTitle={false}>
                <SearchBar
                    disabled={isLoading}
                    value={analysis?.question}
                    sx={{ mb: 2 }}
                />
                <Slide
                    context={{ ...slideContext, chart: analysisChart }}
                    statisticalLines={statisticalLines}
                    loading={isLoading}
                    hideInsights={analysisHideInsights}
                    onAnalysisLinkClick={analysisId => navigate({ pathname: `../ask/${analysisId}`, search: searchParams.toString() })}
                    disableDrillDown={analysis?.relatedAnalyses?.down == undefined}
                    disableDrillAcross={analysis?.relatedAnalyses?.across == undefined || isEmpty(analysis?.relatedAnalyses?.across)}
                    onDrillDown={onDrillDown}
                    onChartContextMenu={onChartContextMenu}
                />
                {
                    analysis?.relatedAnalyses
                        ? (
                            <Menu
                                open={Boolean(contextMenuDimId) && Boolean(contextMenuAnchor)}
                                onClose={closeContextualMenu}
                                anchorReference="anchorPosition"
                                anchorPosition={contextMenuAnchor}
                            >
                                {
                                analysis?.relatedAnalyses?.down
                                    ? (
                                        <MenuItem onClick={() => {
                                            drill(analysis.relatedAnalyses.down, searchParams, filtersInDimensions, filtersInParameters, contextMenuDimId);
                                            closeContextualMenu();
                                        }}
                                        >
                                            <ListItemIcon>
                                                <TrendingDownIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {config.i18n.Dimension[analysis.relatedAnalyses.down.label] || analysis.relatedAnalyses.down.label}
                                            </ListItemText>
                                        </MenuItem>
                                        )
                                    : null
                            }
                                {
                                analysis?.relatedAnalyses?.across.map((targetAnalysis) => {
                                    return (
                                        <MenuItem
                                            data-cy={targetAnalysis.label}
                                            key={targetAnalysis.analysis_id}
                                            onClick={() => {
                                                drill(targetAnalysis, searchParams, filtersInDimensions, filtersInParameters, contextMenuDimId);
                                                closeContextualMenu();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <TrendingFlatIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>{config.i18n.Dimension[targetAnalysis.label] || targetAnalysis.label}</ListItemText>
                                        </MenuItem>
                                    );
                                })
                            }
                            </Menu>
                            )
                        : null
                }
            </AdvisorContainer>
            {analysis && analysisChart && !isUndefined(analysisHideInsights) && analysisParameters && filtersInDimensions
            && filtersInParameters && filtersOutDimensions && filtersOutParameters && analysisCustomParameters && analysisMetadata
                ? (
                    <Drawer
                        variant="persistent"
                        anchor="right"
                        open={isCustomizationDrawerOpen}
                        sx={{
                            "width": isCustomizationDrawerOpen ? drawerWidth : 0,
                            "flexShrink": 0,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                boxSizing: "border-box",
                            },
                        }}
                    >
                        <Toolbar />
                        <IconButton
                            onClick={() => setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen)}
                            sx={{ mb: -2, mr: 1, alignSelf: "end" }}
                        >
                            <KeyboardDoubleArrowRightIcon />
                        </IconButton>
                        <CustomizationBar
                            disabled={isLoading}
                            customizationParameters={analysis.customizationParameters}
                            analysisChart={analysisChart}
                            analysisHideInsights={analysisHideInsights}
                            statisticalLines={{
                                statisticalLines: statisticalLines,
                                disabled: !(statisticalLines),
                            }}
                            analysisParameters={analysisParameters}
                            UIFiltersInDimensions={filtersInDimensions}
                            UIFiltersInParameters={filtersInParameters}
                            UIFiltersOutDimensions={filtersOutDimensions}
                            UIFiltersOutParameters={filtersOutParameters}
                            analysisCustomParameters={analysisCustomParameters}
                            analysisMetadata={analysisMetadata}

                            onChartUpdate={setAnalysisChart}
                            onHideInsightsUpdate={setAnalysisHideInsights}
                            onStatisticalLinesUpdate={setStatisticalLines}

                            onParameterUpdate={(changedParameters) => {
                                const newAnalysisParameters = {
                                    ...analysisParameters,
                                    ...changedParameters,
                                };

                                setAnalysisParameters(newAnalysisParameters);
                            }}

                            onAnalysisFiltersInUpdate={(dimensions, dimParameters, metadata = null) =>
                                updateFilter(
                                    filtersInDimensions,
                                    setFiltersInDimensions,
                                    filtersInParameters,
                                    setFiltersInParameters,
                                    dimensions,
                                    dimParameters,
                                    metadata)}

                            onAnalysisFiltersOutUpdate={(dimensions, dimParameters, metadata = null) =>
                                updateFilter(
                                    filtersOutDimensions,
                                    setFiltersOutDimensions,
                                    filtersOutParameters,
                                    setFiltersOutParameters,
                                    dimensions,
                                    dimParameters,
                                    metadata)}

                            onCustomParameterUpdate={(newCustomParams) => {
                                const newCustomParameters = {
                                    ...analysisCustomParameters, ...newCustomParams,
                                };

                                setAnalysisCustomParameters(newCustomParameters);
                            }}
                        />
                    </Drawer>
                    )
                : null}
            { !isCustomizationDrawerOpen
                ? (
                    <Box position="absolute" right={0} top={0}>
                        <Toolbar />
                        <IconButton
                            onClick={() => setIsCustomizationDrawerOpen(!isCustomizationDrawerOpen)}
                        >
                            <KeyboardDoubleArrowLeftIcon />
                        </IconButton>
                    </Box>
                    )
                : null}
        </>
    );
};

export default Ask;
