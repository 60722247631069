import { getCategoricalAxisOptions, getFormattedValue, getFormatter, getNumericalAxisOptions, getTooltipLabel, getXData } from "./common";
import theme from "../../theme/theme";

export function cutoffYAxis(yData) {
    let cumul = 0;
    const cumulative = yData.map((value, index) => {
        cumul += value;
        // know the y-axis value of each point on the chart, we recursively add all data values (except the extremes)
        return index === yData.length - 1 ? value : cumul;
    });

    // list with non-extreme chart values, that is, the variance values
    // we use the absolute because we want to know the largest bar (maxDims)
    const ySeriesAbs = yData.slice(1, -1).map(value => Math.abs(value));

    let min = Math.min(cumulative);
    let max = Math.max(cumulative);
    const maxDims = Math.max(ySeriesAbs);

    const minMultiplier = [1e12, 1e9, 1e6, 1e3, 1]
        .find(data => min >= data) || 1;
    const maxMultiplier = [1e12, 1e9, 1e6, 1e3, 1]
        .find(data => Math.abs(max) >= data) || 1;

    min = Math.round(min / minMultiplier * 100) / 100;
    max = Math.round(max / maxMultiplier * 100) / 100;
    const minMaxDims = Math.round(maxDims / minMultiplier * 100) / 100;
    const maxMaxDims = Math.round(maxDims / maxMultiplier * 100) / 100;

    // round value to be a multiple of 10: 10, 20, 30, ... or, if the granularity is smaller, a multiple of 2: 2, 4, 6, 8, 10...
    let minAxis = min <= minMaxDims ? 0 : (Math.round((min - minMaxDims) / 10) * 10);
    let maxAxis = Math.abs(max) <= maxMaxDims ? 0 : (Math.round((Math.abs(max) - maxMaxDims) / 10) * 10);
    // minAxis constraint : it needs to be smaller than the min data value but greater than 0
    // maxAxis constraint : it needs to be greater than the max data value but smaller than 0
    // add two decimal digits because of the thinner granularity
    minAxis = minAxis < min && minAxis >= 0 ? minAxis : (Math.round(((min - minMaxDims) / 2) * 100) * 2) / 100;
    maxAxis = maxAxis < Math.abs(max) && maxAxis >= 0 ? maxAxis : (Math.round(((Math.abs(maxAxis) - maxMaxDims) / 2) * 100) * 2) / 100;

    return {
        // auto scale (undefined) Y axis
        min: min >= 0 && max >= 0 ? minAxis * minMultiplier : undefined,
        max: min <= 0 && max <= 0 ? -maxAxis * maxMultiplier : undefined,
    };
}

export const getTooltipLabelWithDimension = (name, metadata, value, config) => {
    if (metadata[value]) {
        return `${metadata[value]?.dimension} ${getTooltipLabel(value, metadata, config)}`;
    } else {
        return getTooltipLabel(value, metadata, config);
    }
};

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    const yData = data.result.map(entry => entry.y[0]);

    // the bottom stack is transparent (visual hack to seem a waterfall)
    let sum = 0;
    let prev = 0;
    const transparentData = yData.map((value) => {
        sum += prev;
        prev = value;
        return sum;
    });
    transparentData[yData.length - 1] = 0;

    // random name to stack series
    return {
        xAxis: getCategoricalAxisOptions(data.labels.x, getXData(data), data.metadata, config, baseFontSize, 1),
        yAxis: {
            ...getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
            ...cutoffYAxis(yData),
        },
        tooltip: {
            confine: true,
            textStyle: {
                fontSize: 0.4 * baseFontSize,
            },
            trigger: "item",
            valueFormatter: getFormatter(data, "y", config, false), // not compacted
            formatter: (params) => {
                // transparent series does not have tooltip !
                if (params.seriesName !== "transparent") {
                    const name = getTooltipLabelWithDimension(params.name, data.metadata, data.result[params.dataIndex].label, config);
                    const formatter = getFormatter(data, "y", config, false);

                    return `${name} <br/> ${params.marker}${formatter(params.value)}`;
                }
            },
        },
        series: [{ // all series have the same size, so we can iterate positiveData and use its index
            name: "transparent",
            type: "bar",
            stack: "waterfall",
            itemStyle: {
                borderColor: "transparent",
                color: "transparent",
            },
            data: transparentData,
        }, {
            name: "data_series",
            type: "bar",
            stack: "waterfall",
            // stacks positive values at the inside-bottom of a negative transparent, and
            // at the top of a positive transparent. Does the opposite for negative values.
            stackStrategy: "all",
            data: yData,
            itemStyle: {
                color: (value) => {
                    // Extreme columns should have a different color when they represent a cumulative value (expenses, profit, total).
                    // The last column is always a cumulative value in waterfall charts. The first only in variance, e.g. (first: expenses, last: spend)
                    if (value.dataIndex === 0 || value.dataIndex === data.result.length - 1) {
                        return theme.palette.echartsBlue.main;
                    } else if (value.data >= 0) {
                        return theme.palette.echartsLightGreen.main;
                    } else {
                        return theme.palette.echartsRed.main;
                    }
                },
            },
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: value => getFormattedValue(value.data, "y", data, config),
            },
        }],
    };
};
