import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress, Collapse, Fade, Paper, SpeedDial, SpeedDialAction, styled, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { cleanStringForSlide, downloadSlide, isNotMarkdownLink } from "../util/slide_util";
import { hasValue } from "../util/util";
import AspectRatioBox from "../util/AspectRatioBox";
import { formatTemplate } from "../util/formatter";
import Chart from "./Chart";
import ComponentLoading from "./loading/ComponentLoading";
import { isEmpty, upperFirst } from "lodash";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { AppContext } from "../AppRouter";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const AdvisorFullScreen = styled(FullScreen)({ // Style component to center child components at the center of the full screen
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
});

const Slide = ({
    context,
    statisticalLines,
    loading,
    hideInsights,
    hideLinks,
    disableDrillDown,
    disableDrillAcross,
    onAnalysisLinkClick,
    onDrillDown,
    onChartContextMenu,
}) => {
    const { config, notify } = useContext(AppContext);
    const [slideRef, setSlideRef] = useState();
    const [baseFontSize, setBaseFontSize] = useState();
    const [isHovering, setIsHovering] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState();
    const ref = useRef();
    const fullScreenHandle = useFullScreenHandle();

    const issueDownloadSlide = (title, subtitle, insights, annotations) => {
        setLoadingDownload(true);
        const image64 = ref.current.exportImage();
        downloadSlide(title, subtitle, !hideInsights ? insights : [], annotations, image64, question,
            () => setLoadingDownload(false),
            () => {
                setLoadingDownload(false);
                notify.error("", "analysis.slide.download");
            });
    };

    // re-compute slide font size when window is resized
    useEffect(() => {
        if (slideRef) {
            const onResize = () => {
                if (slideRef) {
                    const width = slideRef.clientWidth;
                    setBaseFontSize(width / 35);
                }
            };

            onResize();

            const observer = new ResizeObserver(onResize);

            observer.observe(slideRef);

            return () => {
                observer.disconnect();
            };
        }
    }, [slideRef]);

    // https://tkdodo.eu/blog/avoiding-use-effect-with-callback-refs
    const slideRefCallback = useCallback(setSlideRef, []);

    // let's show a placeholder slide, when there is no context or no data and we're still loading data.
    if (!context || !context.data || loading) {
        return (
            <AspectRatioBox ratio={16 / 9}>
                <Paper
                    elevation={3}
                    sx={{ display: "flex", flexDirection: "column", fontSize: baseFontSize }}
                    ref={slideRefCallback}
                >
                    <ComponentLoading loading={loading} label={config.i18n.slide.loading} />
                </Paper>
            </AspectRatioBox>
        );
    }

    const { question, data } = context;

    //  group insights by overridable and the not overridable & apply variables to insights templates
    const insights = data.insights.overridable.concat(data.insights.not_overridable).map((insight) => {
        return formatTemplate(
            insight,
            data.variables,
            config.locale,
            config.i18n,
            data.metadata);
    }).map(i => upperFirst(i.trimStart()));

    // title is always the first insight, or the question if no insights
    let title = upperFirst(formatTemplate(
        data.insights.title,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata).trimStart());

    const subtitle = data.variables.dimensions && formatTemplate(
        data.variables.dimensions,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata);

    const formatedQuestion = formatTemplate(
        question,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata);

    if (hasValue(subtitle)) {
        title += "...";
    }

    // apply variables to footer template
    const footers = data.footers?.map(footer => formatTemplate(
        footer,
        data.variables,
        config.locale,
        config.i18n,
        data.metadata));
    //
    // the main fontSize is set in pixels (at the StyledPaper component)
    // all other JSX paddings, margins and fontSize must be set in "em" to be relative to the parent
    // the chartOptions are set as a multiplier of the main font size (which is equivalent to using "em")
    //
    return (
        <AdvisorFullScreen handle={fullScreenHandle}>
            <AspectRatioBox ratio={16 / 9}>
                <Paper
                    data-cy="slide"
                    onMouseOver={() => setIsHovering(true)}
                    onMouseOut={() => setIsHovering(false)}
                    elevation={3}
                    sx={{ display: "flex", flexDirection: "column", fontSize: baseFontSize }}
                    ref={slideRefCallback}
                >
                    <ComponentLoading loading={loading} label={config.i18n.slide.loading} />
                    {baseFontSize && data.result?.length > 0
                        ? (
                            <>
                                <Box sx={{ flex: 1, display: "flex", flexDirection: "column", pt: "0.75em", px: "1.5em" }}>
                                    {
                                        title
                                            ? (
                                                <Typography variant="h5" align="left" sx={{ fontSize: "1em" }}>
                                                    <Markdown data-cy="slide-title">{title}</Markdown>
                                                </Typography>
                                                )
                                            : null
                                    }
                                    <Fade in={loadingDownload || isHovering}>
                                        <SpeedDial
                                            size="small"
                                            ariaLabel="Slide SpeeDial"
                                            direction="left"
                                            icon={<SpeedDialIcon />}
                                            sx={{ position: "absolute", right: 0.75 * baseFontSize, bottom: 0.75 * baseFontSize }}
                                        >
                                            <SpeedDialAction
                                                icon={loadingDownload ? <CircularProgress size="1.2rem" thickness={5} /> : <DownloadIcon />}
                                                tooltipTitle={config.i18n.button.download}
                                                onClick={() => issueDownloadSlide(
                                                    cleanStringForSlide(title),
                                                    cleanStringForSlide(subtitle),
                                                    insights.filter(isNotMarkdownLink)
                                                        .map(insight => cleanStringForSlide(insight)),
                                                    footers.filter(footer => !isEmpty(footer))
                                                        .map(footer => cleanStringForSlide(footer)))}
                                            />
                                            <SpeedDialAction
                                                icon={fullScreenHandle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                                tooltipTitle={config.i18n.button.full_screen}
                                                onClick={fullScreenHandle.active ? fullScreenHandle.exit : fullScreenHandle.enter}
                                            />
                                        </SpeedDial>
                                    </Fade>
                                    {
                                        subtitle
                                            ? (
                                                <Typography variant="subtitle1" align="left" sx={{ fontSize: "0.5em", color: "grey.700" }}>
                                                    <Markdown data-cy="slide-subtitle">{subtitle}</Markdown>
                                                </Typography>
                                                )
                                            : null
                                    }

                                    <Box sx={{ flex: 1, display: "flex", flexDirection: "row", mt: "1.25em", mb: "0.5em" }}>
                                        <Box
                                            data-cy="slide-chart"
                                            sx={{ flex: 2 }}
                                        >
                                            <Chart
                                                type={context.chart}
                                                statisticalLines={statisticalLines}
                                                title={formatedQuestion}
                                                baseFontSize={baseFontSize}
                                                data={data}
                                                ref={ref}
                                                disableDrillDown={fullScreenHandle.active ? true : disableDrillDown}
                                                disableDrillAcross={fullScreenHandle.active ? true : disableDrillAcross}
                                                onDrillDown={onDrillDown}
                                                onContextMenu={onChartContextMenu}
                                            />
                                        </Box>
                                        <Collapse
                                            in={!hideInsights}
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                pl: "1em",
                                            }}
                                            unmountOnExit
                                        >
                                            <Box data-cy="slide-insights">
                                                {insights && insights.length > 0 ? (
                                                    insights.filter(insight => (!hideLinks && !fullScreenHandle.active) || isNotMarkdownLink(insight)).map((insight, index) => (
                                                        <Box
                                                            key={`insight_${index}`}
                                                            data-cy={`slide-insight-${index}`}
                                                            sx={{
                                                                my: "0.5em",
                                                                ml: "1em",
                                                                fontSize: "0.5em",
                                                            }}
                                                        >
                                                            <Markdown
                                                                options={{
                                                                    overrides: {
                                                                        a: {
                                                                            props: {
                                                                                onClick: (ev) => {
                                                                                    ev.preventDefault();
                                                                                    const baseUrl = window.location.origin;
                                                                                    const newAnalysisId = ev.target.href.replace(`${baseUrl}/ask/`, "");

                                                                                    onAnalysisLinkClick(newAnalysisId);
                                                                                },
                                                                            },
                                                                        },
                                                                    },
                                                                }}
                                                            >
                                                                {insight}
                                                            </Markdown>
                                                        </Box>
                                                    ))
                                                )
                                                    : (
                                                        <Box
                                                            sx={{
                                                                my: "0.5em",
                                                                pl: "1em",
                                                                fontSize: "0.5em",
                                                                fontStyle: "italic",
                                                            }}
                                                        >
                                                            {config.i18n.slide.no_insights}
                                                        </Box>
                                                        )}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </Box>
                                {
                                    footers
                                        ? (
                                            <Typography
                                                data-cy="slide-footer"
                                                variant="caption"
                                                sx={{
                                                    textAlign: "left",
                                                    py: "1em",
                                                    px: "4.25em",
                                                    fontSize: "0.32em",
                                                    lineHeight: "1.5em",
                                                    whiteSpace: "pre-line",
                                                    letterSpacing: "0",
                                                }}
                                            >
                                                <Markdown>{footers.join("\n")}</Markdown>
                                            </Typography>
                                            )
                                        : null
                                }
                            </>
                            )
                        : (
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                    fontSize: "0.65em",
                                    fontStyle: "italic",
                                    textAlign: "center",
                                    color: "grey.700",
                                    backgroundColor: "grey.100",
                                }}
                            >
                                {config.i18n.slide.no_data}
                            </Box>
                            )}
                </Paper>
            </AspectRatioBox>
        </AdvisorFullScreen>
    );
};

Slide.propTypes = {
    context: PropTypes.object,
    statisticalLines: PropTypes.array,
    loading: PropTypes.bool,
    hideInsights: PropTypes.bool,
    hideLinks: PropTypes.bool,
    disableDrillDown: PropTypes.bool,
    disableDrillAcross: PropTypes.bool,
    onAnalysisLinkClick: PropTypes.func,
    onDrillDown: PropTypes.func,
    onChartContextMenu: PropTypes.func,
};

Slide.defaultProps = {
    hideLinks: false,
    onAnalysisLinkClick: () => { },
    onDrillDown: () => { },
    onChartContextMenu: () => { },
};

export default Slide;
