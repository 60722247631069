import { Box, Button, TextField, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import Scope from "./Scope";
import CircularLoading from "../../components/loading/CircularLoading";
import DimensionBox from "../../components/DimensionBox";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { hasValue } from "../../util/util";
import AdvisorContainer from "../../layout/AdvisorContainer";
import ClickableDatePicker from "../../components/ClickableDatePicker";
import { Stack } from "@mui/system";
import { AppContext } from "../../AppRouter";
import { createProject as createProjectApi, getProject } from "../../util/client";

const ProjectDetails = ({ operation }) => {
    const { client, config, notify } = useContext(AppContext);

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState(config.time.reference.min);
    const [endDate, setEndDate] = useState(config.time.reference.max);
    const [scope, setScope] = useState(null);
    const [scopeMetadata, setScopeMetadata] = useState({});

    const [shouldValidate, setShouldValidate] = useState(false);

    const [waitingProjectAction, setWaitingProjectAction] = useState(false);

    const { projectId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (projectId) {
            getProject(client, projectId)
                .then((project) => {
                    setName(project.name);
                    setStartDate(project.start_date);
                    setEndDate(project.end_date);
                    setScope(project.scope[0]); // FIXME #7049: Back-end returns a list of scopes, instead of a single scope
                    setScopeMetadata(project.metadata);
                })
                .catch((error) => {
                    setName("");
                    setStartDate(null);
                    setEndDate(null);
                    setScope(null);
                    setScopeMetadata({});
                    notify.error(error, "procurement.project.projects.fetch");
                });
        }
    }, [projectId]);

    const isValid = () => {
        if (!name) {
            notify.warn("procurement.project.name");
            return false;
        }

        if (!startDate || !endDate) {
            notify.warn("procurement.project.date");
            return false;
        }

        if (scope) {
            if (!scope.name) {
                notify.warn("procurement.project.scope.filter.name");
                return false;
            } else if (isEmpty(scope.filters_in)) {
                notify.warn("procurement.project.scope.filter.where");
                return false;
            }
        }

        return true;
    };

    const createProject = () => {
        setShouldValidate(true);
        if (isValid()) {
            const finalScope = scope ? [{
                id: scope.id,
                name: scope.name,
                filters_in: scope.filters_in,
                filters_out: {},
            }] : []; // FIXME #7049: Back-end only accepts a list of scopes, instead of a single scope

            setWaitingProjectAction(true);
            createProjectApi(client, name, startDate, endDate, finalScope)
                .then(() => {
                    setWaitingProjectAction(false);
                    navigate(`/procurement`);
                })
                .catch((error) => {
                    setWaitingProjectAction(false);
                    notify.error(error, "procurement.project.projects.create");
                });
        }
    };

    const editProject = () => {
        setShouldValidate(true);
        if (projectId && isValid()) {
            setWaitingProjectAction(true);
            client.procurement.procurementUpdateProject(projectId,
                {
                    name: name,
                })
                .then(() => {
                    setWaitingProjectAction(false);
                    navigate(`/procurement`);
                })
                .catch((error) => {
                    setWaitingProjectAction(false);
                    notify.error(error, "procurement.project.projects.update");
                });
        }
    };

    // should only render when creating project, or when the info is available for editing details, meaning the project was get.
    if (operation === "edit" && !name && !startDate && !endDate && !scope) {
        return (
            <AdvisorContainer>
                <CircularLoading flex={1} label={config.i18n.procurement.project.loading} />
            </AdvisorContainer>
        );
    }

    const minDate = config.time.historical.min;
    const maxDate = config.time.historical.max;

    return (
        <AdvisorContainer
            title={operation === "create"
                ? config.i18n.procurement.project.create_label
                : config.i18n.procurement.project.edit_label}
        >
            <Stack width="50%" spacing={1}>
                <TextField
                    required
                    size="small"
                    error={shouldValidate ? !name : null}
                    helperText={shouldValidate && !name ? config.i18n.warn.field_empty : ""}
                    label={config.i18n.procurement.project.name_column}
                    value={name}
                    autoComplete="off"
                    onChange={e => setName(e.target.value)}
                    data-cy="project_name"
                />
                <DimensionBox title={config.i18n.procurement.project.date_label}>
                    <Stack alignItems="stretch" spacing={2}>
                        <ClickableDatePicker
                            required
                            size="small"
                            views={["year", "month"]}
                            openTo="month"
                            label={config.i18n.procurement.project.start_date_column}
                            value={startDate || null}
                            onAccept={date => setStartDate(date.startOf("month").startOf("date"))}
                            disableFuture
                            minDate={minDate}
                            maxDate={endDate || maxDate || null}
                            disabled={operation === "edit"}
                            dataCyProp="startDate_selector"
                        />

                        <ClickableDatePicker
                            views={["year", "month"]}
                            openTo="month"
                            label={config.i18n.procurement.project.end_date_column}
                            value={endDate || null}
                            onAccept={date => setEndDate(date.endOf("month").endOf("date"))}
                            disableFuture
                            minDate={startDate || minDate || null}
                            maxDate={maxDate}
                            disabled={operation === "edit"}
                            dataCyProp="endDate_selector"
                        />
                    </Stack>
                </DimensionBox>
                {
                    scope
                        ? (
                            <Scope
                                scope={scope}
                                scopeMetadata={scopeMetadata}
                                disabled={operation === "edit"}
                                shouldValidate={shouldValidate}
                                onUpdate={(scope, metadata = null) => {
                                    setScope(scope);
                                    if (hasValue(metadata)) {
                                        setScopeMetadata(metadata);
                                    }
                                }}
                                parameters={{ DATE: [startDate, endDate] }}
                            />
                            )
                        : operation === "create"
                            ? (
                                <Button
                                    variant="contained"
                                    color="grey"
                                    title={config.i18n.procurement.scope.add_scope_button}
                                    onClick={() => {
                                        setScope({ id: uuidv4(), name: "", filters_in: {} });
                                    }}
                                    data-cy="add_scope_button"
                                >
                                    {config.i18n.procurement.scope.add_scope_button}
                                </Button>
                                )
                            : <Typography>{config.i18n.procurement.scope.no_scope}</Typography>
                }
                <Box sx={{ display: "flex", mt: 2 }}>
                    <Button
                        variant="contained"
                        color="grey"
                        disabled={waitingProjectAction}
                        sx={{ mr: 1 }}
                        title={config.i18n.button.cancel}
                        component={RouterLink}
                        to="/procurement"
                    >
                        {config.i18n.button.cancel}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={waitingProjectAction}
                        loadingPosition="start"
                        startIcon={operation === "create" ? <AddIcon /> : <SaveIcon />}
                        title={operation === "create"
                            ? config.i18n.button.create
                            : config.i18n.button.save}
                        onClick={() => operation === "create" ? createProject() : editProject()}
                        sx={{ mr: 1 }}
                        data-cy="create_project_button"
                    >
                        {operation === "create"
                            ? config.i18n.button.create
                            : config.i18n.button.save}
                    </LoadingButton>
                </Box>
            </Stack>
        </AdvisorContainer>
    );
};

ProjectDetails.propTypes = {
    operation: PropTypes.string,
};

export default ProjectDetails;
