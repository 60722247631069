import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";

import Header from "./Header";
import Menu from "./Menu";
import CircularLoading from "../components/loading/CircularLoading";
import { AppContext } from "../AppRouter";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
    const { config, user } = useContext(AppContext);
    const [isDrawerPinned, setIsDrawerPinned] = useState(true);
    const navigate = useNavigate();

    if (!config || !user) {
        return <CircularLoading flex={1} />;
    }

    return (
        <>
            <Header
                isDrawerPinned={isDrawerPinned}
                onPinDrawerClick={() => setIsDrawerPinned(!isDrawerPinned)}
            />
            <Box flex={1} display="flex" flexDirection="row">
                <Menu isDrawerPinned={isDrawerPinned} />
                <ErrorBoundary fallback={({ resetError }) => {
                    // We navigate to /error instead of just returning ErrorContainer because resetError alone will break routes.
                    navigate("/error");
                    resetError();
                }}
                >
                    {children}
                </ErrorBoundary>
            </Box>
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.any,
};

export default Layout;
