import { getFormattedValue, getFormatter, getLegend, getNumericalAxisOptions, getTooltipLabel } from "../common";
import { getForecastingLine, getFormatterFromDateAgg, getTimeSeriesAxisOptions } from "./common";
import { max, sum } from "lodash";
import { formatValue } from "../../../util/formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    // We want to get the highest column to use it as a scale to display the labels of each block
    const columnTotals = data.result.map(entry => sum(entry.y));
    const maxColumnTotal = max(columnTotals);

    let seriesArray = [...Array(data.result[0].y.length)].map((_, index) => ({
        name: data.series_labels[index],
        type: "bar",
        stack: "y",
        triggerLineEvent: true,
        symbolSize: 0.15 * baseFontSize,
        data: data.result.map(entry => [entry.x.toDate(), entry.y[index]]),
        label: data.result.length <= 12 && data.series_labels.length > 1 ? {
            show: true,
            position: "inside",
            fontSize: 0.25 * baseFontSize,
            formatter: params => Math.abs(params.data[1] / maxColumnTotal) > 0.03 ? getFormattedValue(params.data[1], "y", data, config) : "",
        } : {},
        ...getForecastingLine(data, config, baseFontSize),
    }));

    // Display the total of each stack
    // The way it is displayed depends on whether positive or negative values are present
    const allYValues = data.result.map(entry => entry.y.map(value => value)).flat(2);
    let totalSeries;
    if (seriesArray.length === 1 || allYValues.every(value => value <= 0) || allYValues.every(value => value >= 0)) {
        totalSeries = { // Display the labels at the top or the bottom of the stack, and don't display total line if there is only one stack
            type: "bar",
            stack: "y",
            data: data.result.map((entry) => {
                const total = sum(entry.y);
                return {
                    value: [entry.x.toDate(), total >= 0 ? Number.EPSILON : -Number.EPSILON],
                    label: { position: total >= 0 ? "top" : "bottom" },
                };
            }),
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(sum(data.result[params.dataIndex].y), "y", data, config),
            },
        };
        seriesArray.push(totalSeries);
    } else {
        totalSeries = { // Display a line as the sum of at each stacked column
            name: "Total",
            type: "line",
            data: data.result.map((entry, index) => [entry.x.toDate(), sum(data.result[index].y)]),
            symbolSize: 0.15 * baseFontSize,
            lineStyle: { width: 0.07 * baseFontSize },
            label: {
                show: true,
                position: "top",
                fontSize: 0.3 * baseFontSize,
                formatter: params => getFormattedValue(params.data[1], "y", data, config),
            },
        };
        seriesArray.unshift(totalSeries); // Place this series at the beginning of the array, so that the line has always the same color no matter what
    }

    let chartOptions = {
        ...getLegend(data, config, baseFontSize),
        labelLayout: { hideOverlap: true },
        xAxis: getTimeSeriesAxisOptions(data.labels.x, data, baseFontSize),
        yAxis: getNumericalAxisOptions(data.labels.y, data.result.map(el => el.y), config, data.label_type.y, baseFontSize),
        tooltip: {
            trigger: "axis",
            textStyle: { fontSize: 0.4 * baseFontSize },
            formatter: (params) => {
                let dateFormatter = getFormatterFromDateAgg(data.parameters["DATE_AGG"]);
                const valueFormatter = getFormatter(data, "y", config, false);

                // Start of the tooltip is always the date
                let text = formatValue(params[0].value[0], dateFormatter, config.locale, config.i18n, data.metadata);

                params.forEach((series) => {
                    const name = series.seriesName === "Total" ? series.seriesName : getTooltipLabel(series.seriesName, data.metadata, config);
                    if (Math.abs(series.value[1]) !== Number.EPSILON) {
                        text += `<br/> ${series.marker} ${name} : ${valueFormatter(series.value[1])}`;
                    }
                });

                return text;
            },
        },
        series: seriesArray,
    };

    // Min & Max labels are not formatted to the appropriate time format, so they are not shown
    chartOptions.xAxis.axisLabel.showMaxLabel = false;
    chartOptions.xAxis.axisLabel.showMinLabel = false;

    return chartOptions;
};
